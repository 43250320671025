import React from 'react';
import webDev from '../web-dev.png';
import TypingEffect from './TypingEffect';
import tf from '../tensorflow.svg'
import node from '../node.svg'
import laravel from '../laravel.svg'
import c from '../c.png'
import flask from '../flask.svg'
import arm from '../arm.svg';
import systemc from '../systemc.png'
import azure from '../azure.png'
import react from '../react.svg'
import express from '../express.png'
import mongo from '../mongodb.png'
import junit from '../junit.png'
import aws from '../AWS-Logo.png'
import firebase from '../firebase.png'
import kotlin from '../Kotlin.png'
import { useEffect } from 'react';

function Home() {

  const handleScroll = () => {
    window.scrollBy({ 
        top: 925, // Scroll down 50 pixels
        left: 0, // No horizontal scroll
        behavior: 'smooth' // Smooth scroll
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@splinetool/viewer@1.9.56/build/spline-viewer.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div class="main-content" id="home">
      <div className="relative h-[105vh]">
        {/* <div class="left-align-text">
        <h1>Hey There!<span class="wave">👋🏻</span></h1>
        <h3>I'm Kiro</h3>
        <h4 className="im-a">A <TypingEffect /></h4> */}
        {/* <img src= {webDev} alt="web-dev" className="web-dev-image" /> */}
        <spline-viewer 
        url="https://prod.spline.design/UsAmcL5TAyZvDQpo/scene.splinecode"
        className="w-full h-full"
        ></spline-viewer>
        {/* </div> */}

        <div className="absolute -bottom-[29.9rem] left-0 w-full h-[30rem] bg-gradient-to-t from-transparent via-[#07060780] to-[#070607] z-[1]"></div>

        <div onClick={handleScroll} className="-mt-[38rem] z-10 cursor-pointer" id="scroll">
            <div class="mouse"></div>
        </div>
      </div>

      <h1 className='mt-72'>Past Projects</h1>
      <div className="grid-container">
       
        <div class="card">
          <img alt="node" class="img" src={node}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Brain Lesion Detection and Segmentation</p>
          <span>One of my proudest achievements was developing a TensorFlow brain tumor segmentation model using U-net architecture. This reduced MRI scan processing time 
            from hours to under 15 seconds, enabling real-time lesion detection. We also created a 
            user-friendly interface ensuring patient data privacy. This work significantly improved healthcare delivery at St. Michael’s Hospital.</span>
          <p class="text price"></p>
          {/* <button class="group group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-rose-300 hover:before:[box-shadow:_20px_20px_20px_30px_#a21caf] duration-500 before:duration-500 hover:duration-500 underline underline-offset-2 hover:after:-right-8 hover:before:right-12 hover:before:-bottom-8 hover:before:blur hover:underline hover:underline-offset-4  origin-left hover:decoration-2 hover:text-rose-300 relative bg-202020 h-16 w-64 mt-10 text-left p-3 text-gray-50 text-base font-bold rounded-lg  overflow-x-hidden  before:absolute before:w-12 before:h-12 before:content[''] before:right-1 before:top-1 before:z-10 before:bg-violet-500 before:rounded-full before:blur-lg  after:absolute after:z-10 after:w-20 after:h-20 after:content['']  after:bg-rose-300 after:right-8 after:top-3 after:rounded-full after:blur-lg">
            See more
          </button> */}
          <a href='https://drive.google.com/file/d/1IrTtG-Mq1y-LOgR4FI0UZJ6s1faRqLjD/view?usp=sharing' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">View Demo</span>
          </a>
          </div>
      </div>

        <div class="card">
          <img alt="tensorflow" class="img" src={tf}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Lip Reading model</p>
          <span>This project is useful in assisting audio-based speech recognition, biometric authentication, and 
            aiding hearing-impaired people in their daily lives. The objectives of this are to train a model using the algorithm created, 
            to take an input video and make an accurate prediction on what the person is saying with a high accuracy rate. LipNet introduces an end-to-end sentence-level 
            lipreading model, addressing the challenging task of mapping variable-length video sequences to text. 
            It utilizes spatiotemporal convolutions, bidirectional GRUs, and connectionist temporal classification loss.</span>
          <p class="text price"></p>
          <a href='https://www.kaggle.com/code/kiroyoussef/lip-reading/' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">View Code</span>
          </a>
          </div>
      </div>

        <div class="card">
          <img alt="tensorflow" class="img" src={aws}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">OuchRx</p>
          <span>I developed and deployed OuchRx, an asynchronous telemedicine platform aimed at simplifying pain management. Patients use our web interface to submit their symptoms and medical history, which are reviewed by healthcare professionals who then issue tailored prescriptions. 
            Medications can be ordered directly through the platform, streamlining the process. I leveraged AWS services to build and scale the solution effectively: Namely AWS RDS, AWS S3, and AWS CloudFormation.</span>
             <p class="text price">UNDER DEVLOPMENT</p>
          <a href='https://main.d1p6lt27x4dfjq.amplifyapp.com/' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Website</span>
          </a>
          </div>
      </div>

        <div class="card">
          <img alt="tensorflow" class="img" src={firebase}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Emla Checkout Flow</p>
          <span>I was contracted to develop a streamlined checkout flow for emla.ca, a platform allowing patients to purchase medications directly from the website. 
            Using Next.js for a responsive and performant front-end, Tailwind CSS for a modern and flexible design, and Firebase for real-time data management and authentication, 
            I created an efficient and user-friendly checkout experience. This project involved integrating secure payment processing and ensuring a smooth, intuitive user journey from selection to purchase.
             My work highlights my proficiency in modern web technologies and commitment to enhancing user experiences in healthcare e-commerce.</span>
          <p class="text price"></p>
          <a href='https://emla-checkout.vercel.app/' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Website</span>
          </a>
          </div>
      </div>

        
        <div class="card">
          <img alt="lavarel" class="img" src={laravel} id="bigger-img"/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Property Managment Software (DoorVela)</p>
          <span>Doorvela is an all in one property management software looking to fill the gaps
            in the space. Working with the team, we've closely studied the competition and conducted research
            with landlords & property managers to create a software tailored for their
            needs in record time.</span>
          <p class="text price">UNDER DEVLOPMENT</p>         
          <a href='https://www.doorvela.com/' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Website</span>
          </a>
          </div>
      </div>

        <div class="card">
          <img alt="arm" class="img" src={arm} id="arm"/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Embedded Multimedia Center</p>
          <span>This project introduces a Media Center featuring a graphical interface on a Color LCD. 
            Controlled through a joystick, the system includes a Photo Gallery, MP3 Player, and Game Center. 
            Users can navigate the menu, view images in the Photo Gallery, play audio files with USB connectivity in the MP3 Player, and 
            enjoy an interactive basketball game in the Game Center. 
            Employing an RTOS for multitasking, this project demonstrates a compact and user-friendly embedded system for multimedia consumption.</span>
          <p class="text price"></p>         
          <a href='https://drive.google.com/file/d/1_DraFdCdDP0iK1L2SeM6cS_TZmTYhy2Y/view?usp=sharing' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">View Demo</span>
          </a>
          </div>
      </div>

        <div class="card">
          <img alt="systemc" class="img" src={systemc} id="arm"/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Torus-Based NoC Design</p>
          <span>Designed a specialized 4x4 mesh NoC with torus topology and wormhole routing, achieving significant
          latency reduction and improved throughput by 30%, validated through SystemC simulations. The design attained over 95% fault recovery rate in simulations, 
          ensuring reliable data transmission under network faults and up to 20% energy savings without compromising performance.</span>
          <p class="text price"></p>         
          <a href='https://github.com/kiro358/Torus-Based-NoC-Design.git' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Code</span>
          </a>
          </div>
      </div>

        <div class="card">
          <img alt="c" class="img" src={kotlin}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Android App Testing</p>
          <span>I was tasked with automating tests for a native mobile application as part of a technical challenge for theScore. Using Appium 2 and Kotlin, 
            I developed and executed automated test scripts that navigate through the app, verifying functionality such as opening league, team, and player pages, 
            interacting with various tabs, and ensuring back navigation accuracy. The automation suite I built includes robust abstractions to support future expansions and tests. 
            This project showcases my skills in mobile test automation and my ability to create scalable, maintainable test suites for native mobile apps.
            </span>
          <p class="text price"></p>         
          <a href='https://drive.google.com/file/d/1kwNnzFS0cN0C_-oGnZTpYGPzBFM_gRYU/view?usp=sharing' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">View Demo</span>
          </a>
          </div>
      </div>


        <div class="card">
          <img alt="c" class="img" src={c}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">P2P File Transfer Platform</p>
          <span>The computer network’s final project is based on the development of a Peer-to-Peer (P2P) network using socket programming in C. 
            The project’s primary objective was to establish a decentralized network where peers can register themselves as users, and upload/register 
            content such as files, movies, videos, etc. Additionally, users must be able to perform content-related operations such as searching, downloading, and deregistering. 
            This P2P network is designed to facilitate communication between peers using User Datagram Protocol (UDP) sockets. 
            </span>
          <p class="text price"></p>         
          <a href='https://github.com/kiro358/Peer-to-Peer-Network' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Code</span>
          </a>
          </div>
      </div>


        <div class="card">
          <img alt="microsoft azure" class="img" src={azure}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Rover Operator Simulation</p>
          <span>Successfully integrated gRPC and RabbitMQ technologies to facilitate communication between rovers, deminers, and ground control within a distributed system. 
            The primary goal was to enable the rovers to explore the terrain, identify mines, and communicate these findings to the deminers via RabbitMQ. 
            The deminers would then disarm the mines and report back to ground control using the same messaging system. This is all done in real-time, after containerizing the app and deploying it on Microsoft Azure.
            </span>
          <p class="text price"></p>         
          <a href='https://coe892lab42022g16.azurewebsites.net/' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Website</span>
          </a>
          </div>
      </div>

        <div class="card">
          <img alt="junit" class="img" src={junit}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Datafaker Library Testing</p>
          <span>This project rigorously tests the Datafaker library, a Java-based tool for generating realistic fake data. We employ JUnit, Clover, and mutation testing to ensure the accuracy and reliability of data across various domains. 
            Our goal is to confirm the library's effectiveness in producing consistent and correctly formatted outputs, enhancing its applicability in software testing and development scenarios.
            </span>
          <p class="text price"></p>         
          <a href='https://github.com/kiro358/datafaker-testing.git' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Code</span>
          </a>
          </div>
      </div>

      <div class="card">
          <img alt="flask" class="img" src={flask}/>
          <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <g id="_1421394342400">
          <g>
            <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33"></polygon>
            <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89"></polygon>
            <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89"></polygon>
            <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33"></polygon>
            <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33"></polygon>
          </g>
          </g>
        </g>
        <div class="textBox">
          <p class="text head">Auction WebApp</p>
          <span>Seamlessly navigate through our user-friendly interface. Explore a vast array of listings, 
            from electronics to antiques, with detailed bid statuses and durations. Empower users to create their own listings effortlessly, 
            while our robust backend ensures secure database management. With real-time bid updates and comprehensive sorting options, bidding has never been more convenient.</span>
          <p class="text price"></p>         
          <a href='https://online-auction-platform.onrender.com/' class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Visit Website</span>
          </a>
          </div>
      </div>

      </div>

      

      <section class="mern-section">
        <h2>This website was designed using the MERN stack</h2>
        <div class="logos-container">
          <img src={mongo} alt="MongoDB" class="logo" id="mongo"/>
          <img src={express} alt="Express.js" class="logo" id="express"/>
          <img src={react} alt="React.js" class="logo"/>
          <img src={node} alt="Node.js" class="logo" id="node"/>
        </div>
      </section>

    </div>
  );
}

export default Home;
